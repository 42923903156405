#solutionpanels {
  .solution-card {
    border-top-width: 1px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    .card-link {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      max-width: 0;
      max-width: 100%;
      &:hover {
        border-bottom: none
      }
    }

    .card-img {
      margin: 0 20px 20px 0;
      width: 60px;
      height: 60px;
      transform: scale(2);
    }

    .card-content {
      width: 100%;
      .card-title {
        font-weight: bold;
      }
      .card-subtitle {

      }
      .card-tags {
        text-align: right;
      }
    }

  }
}


#solutionfilters {
  margin-bottom: 25px;

  .form-control {
    &:focus {
      border-color: $dark;
      box-shadow: 0 0 0 3px rgba($dark, .25);
    }
  }

}
