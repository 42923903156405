/* global helper classes ------- */
.strong {
  font-weight:800;
}
.spacer5,.pad-5 {
  padding:5px;
}
.pad-15 {
  padding:15px; 
}
.pad-30 {
  padding:30px; 
}
.pad-0 {
  padding:0 !important; 
}
.pad-left-0 {
  padding-left:0; 
}
.pad-right-0 {
  padding-right:0;
}
.pad-left-5 {
  padding-left:5px; 
}
.pad-right-5 {
  padding-right:5px;
}
.pad-left-15 {
  padding-left:15px; 
}
.pad-right-15 {
  padding-right:15px; 
}
.pad-left-30 {
  padding-left:30px; 
}
.pad-right-30 {
  padding-right:30px; 
}
.margin-top-0 {
  margin-top:0; 
}
.margin-top-5 {
  margin-top:5px; 
}
.margin-top-10 {
  margin-top:10px; 
}
.margin-top-15 {
  margin-top:15px; 
}
.margin-top-30 {
  margin-top:30px; 
}
.margin-bottom-5 {
  margin-bottom:5px; 
}
.margin-bottom-15 {
  margin-bottom:15px; 
}
.no-margin {
  margin:0 !important;
}
.strike {
  text-decoration:line-through;
}


/*
 * vertical center
 */
.valign {
  float: none;
  display: inline-block;
  vertical-align: middle;
} 

/* 
 * animations
 */
.anim-spin {
  animation: anim-spin 5s infinite linear;
}
@keyframes anim-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.anim-pulse {
  animation: anim-pulse 2s infinite linear;
}
@keyframes anim-pulse {
  0%, 25%, 50%, 75% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

.anim-pulse-spin {
  animation: anim-pulse-spin 5s infinite linear;
}
@keyframes anim-pulse-spin {
  0%, 25%, 50%, 75% {
    opacity: 1;
    transform: rotate(0deg);
  }
  100% {
    opacity: 0.5;
    transform: rotate(359deg);
  }
}

/*
 * Alerts
 */
.alert-icon {
  background-repeat: no-repeat;
  background-position: 16px calc(min(45%, 22px));
  min-height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.alert-info {
    background-image: url("/assets/img/icons/note.svg");
    padding-left: 75px !important;
  }

  &.alert-dark {
    background-image: url("/assets/img/icons/example.svg");
    padding-left: 70px !important;
  }

  &.alert-warning {
    background-image: url("/assets/img/icons/warning.svg");
    padding-left: 90px !important;
  }

  &.alert-danger {
    background-image: url("/assets/img/icons/error.svg");
    padding-left: 80px !important;
  }
}
