.docs-layout {
  @include media-breakpoint-up(md) {
    display: grid;
    gap: $grid-gutter-width;
    grid-template-areas: "sidebar main";
    grid-template-columns: 4fr 8fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-columns: 3fr 9fr;
  }
}

.docs-sidebar {
  grid-area: sidebar;
}

.docs-main {
  grid-area: main;

  @include media-breakpoint-up(md) {
    display: grid;
    gap: inherit;
    grid-template-areas:
      "intro"
      "toc"
      "content";
    grid-template-rows: auto auto 1fr;
  }

  @include media-breakpoint-up(lg) {
    grid-template-areas:
      "intro   toc"
      "content toc";
    grid-template-columns: 4fr 1fr;
    grid-template-rows: auto 1fr;
  }
}

.docs-intro {
  grid-area: intro;
}

.docs-toc {
  grid-area: toc;
}

.docs-content {
  grid-area: content;
  min-width: 1px; // Fix width when bd-content contains a `<pre>` https://github.com/twbs/bootstrap/issues/25410
}