.bnn-header {

  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  @include media-breakpoint-down(xs) {
    padding-left: 0.5rem;
  }

  background-color: $dark-background;

  .navbar-toggler {
    padding: 0;
    border: 0;
  }

  .navbar-nav {
    .nav-link {
      padding-right: $spacer * .25;
      padding-left: $spacer * .25;
      color: rgba($white, .85);

      &:hover,
      &:focus {
        color: $white;
      }

      &.active {
        font-weight: 600;
        color: $white;
      }
    }
  }

  .bnn-btn-header {
    color: $white;
    background-color: transparent;
    border: 1px solid $white;
    letter-spacing: 0.8px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
    padding: 6px 14px;
    margin-top: 10px;
    transition: color .4s ease-in, background-color .1s ease-in;
    &:hover {
      color: $white;
      border-color: $primary;
      background-color: $primary;
    }
  }
}

.bnn-subnav {
  // position and z-index are needed for the dropdown to stay on top of the content
  position: relative;
  z-index: $zindex-sticky;
  background-color: rgba($white, .95);
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .05), inset 0 -1px 0 rgba(0, 0, 0, .15);

  @include media-breakpoint-up(md) {
    position: sticky;
    top: 0;
  }

  .bnn-navsearch {
    position: relative;

    &::after {
      position: absolute;
      top: .4rem;
      right: .4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.5rem;
      padding-right: .25rem;
      padding-left: .25rem;
      @include font-size(.75rem);
      color: $gray-600;
      content: "Ctrl + /";
      border: $border-width solid $border-color;
      @include border-radius(.125rem);
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    .form-control {
      padding-right: 3.75rem;

      &:focus {
        border-color: $dark;
        box-shadow: 0 0 0 3px rgba($dark, .25);
      }
    }
  }

  .bnn-sidebar-toggle {
    color: $text-muted;

    &:hover,
    &:focus {
      color: $dark;
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($dark, .25);
    }

    .bi-collapse { display: none; }

    &:not(.collapsed) {
      .bi-expand { display: none; }
      .bi-collapse { display: inline-block; }
    }
  }
}

.bnn-footer {
  margin-top: 2.5rem;
  .list-unstyled {
    margin-top: 2rem;
  }
  a {
    font-weight: 600;
  }
}

.bnn-hide {
  display: none !important;
}

// search results dropdown
.bnn-search, .bnn-navsearch {
  position: relative;
  .dropdown-menu {
    position: absolute;
    max-height: 600px;
    overflow-y: scroll;

    width: 100%;
    @include media-breakpoint-up(md) {
      width: 600px;
    }
    margin: 0px 20px;
    padding: 0px 20px;

    li {
      padding: 14px 0px;
      border-bottom: 1px $gray-400 solid;
      a {
        color: $black;
        div {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow-x: hidden;  
          &.title {
            font-weight: 600;
            color: $primary;
          }      
          b {
            font-weight: 800;
            color: $orange-butternut;
          }
        }
      }
      &:hover {
        background-color: $gray-200;
      }
    }
  }  
}

// position sticky requires scroll to link logic (header is 51px)
// replaces JS scrollTop animation
@include media-breakpoint-up(md) {
  html {
    scroll-padding-top: 51px;
  }
}
