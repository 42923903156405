.docs-content {

  h4 {
    padding-top: 10px;
  }

  li > p {
    margin: 10px 0px;
  }

  a {
    text-decoration: none;
    &:hover {
      border-bottom: 2px solid $primary;
    }
  }

  // link icon on h3
  a.fragment-link {
    color: $bluegray-100;
    font-size: smaller;
    &:hover {
      border-bottom: none;
      color: $gray-900;
    }
  }

  // lists in docs markdown
  // if <p> is followed by a list
  p:has(+ :is(ol, ul, dl)) {
    margin-bottom: 0.3rem;
  }

  .support {
    .card {
      margin-top: 40px;
      padding: 20px;
      text-align: center;
      img {
        height: 100px;
      }
      &:hover {
        background-color: $gray-200;
      }      
      a:hover {
        border-bottom: none;
      }
    }    
  }
}
