.docs-sidebar {
  padding-left: 0px;

  @include media-breakpoint-down(md) {
    margin: 0 -.75rem 1rem;
  }

  #tocsidebar {
    overflow: auto;

    @include media-breakpoint-up(md) {
      position: sticky;
      top: 5rem;
      // Override collapse behaviors
      // stylelint-disable-next-line declaration-no-important
      display: block !important;
      height: subtract(100vh, 4rem);
      // Prevent focus styles to be cut off:
      padding-left: .25rem;
      margin-left: -.25rem;
      overflow-y: auto;
    }

    font-size: 14px;
    font-weight: 300;
    a {
      color: inherit;
      &:hover {
        color: $primary;
      }
    }

    .toplevel {
      div.section {
        text-transform: uppercase;
        font-size: smaller;
        margin-top: 15px;
        margin-left: 13px;
      }
      div.title {
        margin: 0;
        padding: 3px 10px 3px 10px;
        &.active, &.active a {
          color: $primary;
          font-weight: 400;
          background-color: $bluedocs-100;
        }
        border-left: 3px solid transparent;
        &.active {
          border-left: 3px solid $primary;
        }
        &.nofa {
          padding-left: 22px;
        }
      }
      .bold {
        font-weight: 400;
      }
      .fa {
        padding-right: 4px;
        cursor: pointer;
        &[aria-expanded="false"]:before {
          // fa-caret-right
          content: "\f0da";
        }
        &[aria-expanded="true"]:before {
          // fa-caret-down
          content: "\f0d7";
        }
      }
      .second {
        padding-left: 22px;
      }
      .third {
        padding-left: 22px;
      }
      .fourth {
        padding-left: 22px;
      }
    }
    .label {
      background-color: $orange-butternut;
      color: $white;
      border-radius: 4px;
      margin-left: 2px;
      padding: 2px 4px;
      vertical-align: text-top;
      font-size: 0.5rem;
    }
  }
}
