.docpage {

  h3 {
    font-weight: 600;
  }

  // btn
  .btn-primary a {
    color: $white;
    letter-spacing: 0.8px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
    &:hover {
      border-bottom: none;
    }
  }

  #toctopics {
    p {
      margin-bottom: 0.25rem;
    }
    .indent1 {
      padding-left: 30px;
    }
    .indent2 {
      padding-left: 60px;
    }
  }

  // vids - don't overflow on mobile
  .youtube {
    iframe {
      max-width: 100%;
    }
  }

  // api MD files
  .apispec {
    .mainhead {
      &:before {
        content: "> ";
      }
    }
    .subhead {
      font-weight: bold;
      font-size: 16px;
      margin-top: 20px;
    }
  }

  // code
  pre {
    padding: 10px 10px 0px 10px;
    margin-bottom: 10px;
    tab-size: 4;
  }
  code, kbd, samp {
    font-family: Menlo, Consolas, monospace;
    margin: 0px 3px;
    font-size: 13px;
    line-height: 19px;
    &.highlighter-rouge {
      color: $coral;
      font-size: 14px;
      letter-spacing: 0.3px;
    }
  }

  // tables
  .table {
    thead {
      background-color: $bluegray-400;
      color: $white;
      th {
        font-weight: 600;
      }      
    }
    
    &.table-striped {
      tbody > tr:nth-of-type(odd) {
        background-color: $bluegray-100;
      }
    }
  }

  // img
  .docimg {
    margin: 15px auto 25px auto;
    cursor: zoom-in;
  }

  // tabs
  .bnn-tabs {
    border: 1px $gray-400 solid;
    border-radius: 0.25rem;

    .nav-tabs {
      padding-left: 4px;
      padding-bottom: 1px;
      .nav-link {
        border: 0px;
        padding: 0.5rem;
        &.active {
          font-weight: bold;
          border-bottom: 4px solid $primary;
        }
      }
    }

    .tab-content {
      padding: 0px 10px;
    }
  }

  // toggle
  .bnn-toggle {
    margin-bottom: 15px;
    
    .toggle-title {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 1rem;

      .fa {
        font-size: 1.5rem;
        padding-right: 0.75rem;
        &:before {
          // fa-caret-down
          content: "\f0d7";
        }
      }

      &.collapsed {
        .fa {
          &:before {
            // fa-caret-right
            content: "\f0da";  
          }
        }
      }
    }

    .toggle-content {
      padding-left: 24px;
      // nothing special
    }
  }

}


/*
 * Image modal
 */
.modal-dialog {
  .modal-body {
    .image-wrapper img {
        width: 80%;
    }
  }
}
