.docs-intro {

  .breadcrumb {
    .breadcrumb-item {
      font-size: 0.875rem;
    }
  }

  #titlearea {
    margin-bottom: 6px;
    h2 {
      font-weight: 700;
      margin-top: -14px;
      margin-bottom: 6px;
    }
    .subtitle {
      font-weight: 600;
    }
  }

  #infoarea {
    margin-bottom: 6px;
    font-size: 0.8rem;
    color: $gray-600;
    align-items: center;
    display: flex;
    flex: 1;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        align-items: center;
        display: flex;
        float: left;
        position: relative;
        padding-right: 20px;
        span {
          padding-left: 6px;
        }
        .contr-l {
          padding-right: 6px;
        }
        .contr-r {
          a {
            margin-right: 4px;
          }
          .contr {
            border-radius: 50%;
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }

  hr {
    background: linear-gradient(90deg, $gray-600 0, $white);
    border: none;
    height: 1px;
    margin: 0
  }

}
