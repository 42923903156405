//
// Fonts
//

$fonts-path:  "../fonts";
$img-path:  "../img";
$open_sans-path:  "#{$fonts-path}/open_sans";

//
// Brand colors 
//

$brand-primary: 		  #0087FF;  // Banyan was #23B9D8;
$brand-secondary: 		  #16839E;

$brand-success:         #00C884;
$brand-warning:         #FDCE3A;
$brand-danger:          #E52F18;

//
// Override bootstrap
//

$font-family-base: 'Open Sans', sans-serif;

$primary: 		$brand-primary;
$secondary: 	$brand-secondary;

$info:          $brand-primary;
$success:       $brand-success;
$warning:       $brand-warning;
$danger:        $brand-danger;

$link-decoration: none;
$link-hover-decoration: none;

$font-size-base: 0.875rem;
$input-btn-focus-width: 0px;
$alert-link-font-weight: 400;


//
// Home page
//

$dark-background: 		#00051C;
$home-btn:		 		    #00C1DE;


//
// Docs pages
//

$bluedocs-100:			#F6F7FB;
$bluedocs-200:			darken($bluedocs-100, 5%);
$bluedocs-400:			#597897;
$bluedocs-500:			#455378;
$bluedocs-700:			#071D52;
$bluedocs-900:			#1F2731;

$bluegray-100:			#CACDD8;
$bluegray-200:			#B6B8C9;
$bluegray-400:			#6C6E80;

$coral:					#ff6f61;
$orange-butternut:		#ff6e41 // Banyan was #f07402;
