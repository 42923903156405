.docs-toc {

  @include media-breakpoint-up(lg) {
    position: sticky;
    top: 5rem;
    right: 0;
    z-index: 2;
    height: subtract(100vh, 7rem);
    overflow-y: auto;
  }

  #markdown-toc {
    padding-left: 0px;
    margin: 20px 0px;
    @include media-breakpoint-up(lg) {
      margin: 0px 10px;
    }

    font-size: 0.8rem;
    font-weight: 300;
    color: $gray-900;
    a {
      color: inherit;
      &:hover {
        color: $primary;
        border-bottom: none;
      }
    }

    ul, li {
      list-style: none;
    }
    ul {
      padding: 3px 0px 3px 6px;
    }
    li {
      padding: 3px 0px 0px 6px;       
    }
    li b {
      text-transform: uppercase;
      margin-bottom: 6px;
    }
  }

}
