.homepage, .errorpage {

  .bnn-hero {
    padding-top: 0rem;
    padding-bottom: 6rem;
    background-color: $dark-background;
    color: $white;

    @include media-breakpoint-up(lg) {
      padding-bottom: 4rem;
    }

    .bnn-hero-img {
      width: 100%;
      @include media-breakpoint-up(lg) {
          width: 400px;
      }      
    }

    h2 {
      font-weight: 600;
    }

    .bnn-search {
      .inp-search {
        color: $white;
        background-color: $dark;
        border-color: $dark;
      }
      .btn-search {
        text-transform: uppercase;
        color: $black;
        padding: 0.375rem 2rem;
      }
      #bnn-search-trigger {
        background-color: $white;
        border-color: $white;
        border-radius: 0 4px 4px 0;
        padding: 0 2rem;
      }
    }
  }

  .bnn-quickstart {

    .bnn-guides {
      margin: -60px auto 30px;
      background-color: $white;
      border-radius: 3px;
      position: relative;

      @include media-breakpoint-up(md) {
        margin: -60px auto 50px;
      }

      @include media-breakpoint-up(xl) {
        max-width: 1000px
      }

      .bnn-guide-col {
        padding: 14px 50px;
        flex: 1 1 auto;
        width: 20%;
        .inner {
          position: relative;
          .title, .number {
            font-size: 1rem;
            font-weight: bold;
          }
          .number {
            top: 0;
            left: -16px;
            position: absolute;
          }
          p {
            font-size: 0.8rem;
            margin-bottom: 0.5rem;
          }
          a {
            font-size: 0.8rem;
            font-weight: 600;
          }       
        }
        @include media-breakpoint-up(md) {
          padding: 14px 30px;
        }        
      }


      .bnn-right-arrow-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        .bnn-right-arrow {
          width: 100%;
          height: 100%;
          path {
            fill: none;
            stroke: #eee;
            stroke-width: 2;
            stroke-linejoin: round;
          }
        }
      }

      .bnn-down-arrow {
        width: 100%;
        height: 40px;
        padding: 0px;
        path {
          fill: none;
          stroke: #eee;
          stroke-width: 2;
          stroke-linejoin: round
        }
      }
    }
  }

  .bnn-topics {
    h2 {
      font-weight: bold;
    }

    .card {
      text-align: center;
      .card-body {
        p {
          margin-bottom: 0px;
        }
      }
      .card-img-top {
        margin-top: 1rem;
        height: 66px;
      }
      &:hover {
        background-color: $gray-200;
      }
    }
  }
  
}